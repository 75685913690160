import * as React from "react"
import BlogListEN from "../../components/sections/blog/blog_list_en"
import GradientJumbo from "../../components/gradient-jumbo"
import Layout from "../../components/layout"
import Contact from "../../components/sections/contact"
import Seo from "../../components/seo"

export default function Blog(props) {
  return (
    <Layout language="en" pathname={props.location.pathname}>
      <Seo
        title="Blog"
        lang="en"
        description="Stay up-to-date with all the latest CAMARADES BR news "
      />
      <GradientJumbo isBlog={true} subtitle="Blog" title="Camarades BR News" />
      <div id="plus"></div>
      <BlogListEN />
      <Contact language="en" />
    </Layout>
  )
}
